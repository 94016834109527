import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

const SEO = ({
  title,
  seoTitle,
  description,
  keywords,
  image,
  pathname,
  article
}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
          twitterHandle,
          defaultKeywords
        }
      }
    }) => {
      const seo = {
        title: title || defaultTitle,
        seoTitle: seoTitle || title || defaultTitle,
        description: description || defaultDescription,
        keywords: `${keywords || defaultKeywords}`,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || `/`}`
      };

      return (
        <Helmet
          title={seo.title}
          titleTemplate={titleTemplate}
          htmlAttributes={{ lang: `en` }}
        >
          <meta name="description" content={seo.description} />
          <meta name="keywords" content={seo.keywords} />
          <meta name="image" content={seo.image} />
          {seo.url && <meta property="og:url" content={seo.url} />}
          {(article ? true : null) && (
            <meta property="og:type" content="article" />
          )}
          {seo.title && <meta property="og:title" content={seo.seoTitle} />}
          {seo.description && (
            <meta property="og:description" content={seo.description} />
          )}
          {seo.image && <meta property="og:image" content={seo.image} />}
          <meta name="twitter:card" content="summary_large_image" />
          {twitterHandle && (
            <meta name="twitter:creator" content={twitterHandle} />
          )}
          {seo.title && <meta name="twitter:title" content={seo.seoTitle} />}
          {seo.description && (
            <meta name="twitter:description" content={seo.description} />
          )}
          {seo.image && <meta name="twitter:image" content={seo.image} />}
        </Helmet>
      );
    }}
  />
);

SEO.propTypes = {
  title: PropTypes.string,
  seoTitle: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.array,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool
};

SEO.defaultProps = {
  title: null,
  seoTitle: null,
  description: null,
  keywords: null,
  image: null,
  pathname: null,
  article: false
};

export default SEO;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: siteUrl
        defaultImage: image
        twitterHandle
        defaultKeywords: keywords
      }
    }
  }
`;
